import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function cellEditDeleteButton(onEdit, onDelete) {
  return class extends GridCell {

    render() {
      return (
        <td>

          <button class="k-button k-flat k-button-icontext" onClick={() => { onEdit(this.props.dataItem); }}>
    <span role="presentation" class="k-icon k-i-edit"></span></button>
          <button class="k-button k-flat k-button-icontext" onClick={() => { onDelete(this.props.dataItem); }}>
    <span role="presentation" class="k-icon k-i-delete"></span></button>


          {/* <button
            className="k-button"
            onClick={() => { onDelete(this.props.dataItem); }}
          >
            <i class="fa fa-times" aria-hidden="true"></i>

          </button> */}



        </td>




      );
    }
  };
}