import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../Constants/index";
import { getIdToken } from "firebase/auth";
import { useState } from "react/cjs/react.production.min";
import Select from 'react-select';

const ExpirationNoteLkp = (props) => {

    const [selectedOption, setSelectedOption] = React.useState(props.defaultValue);

    React.useEffect(() => {
         setSelectedOption(props.defaultValue);
    }, [props.defaultValue]);

    const options =
        [{
            value: "Full training is required after 1 year.",
            label: 'Full training is required after 1 year.'
        },
        {
            value: 'Full training is required after 2 years.',
            label: 'Full training is required after 2 years.',
        },
        {
            value: 'Full training is required after 3 years.',
            label: 'Full training is required after 3 years.'
        }
            ,
        {
            value: 'Full training is required after 5 years.',
            label: 'Full training is required after 5 years.'
        }
        ];


    const selectedOptionHandler = (selectedValue) => {
        setSelectedOption(selectedValue)
        props.onChange(selectedValue.value);
    }


    return (
        <>
            <Select className="form-control"
                value={selectedOption}              
                onChange={selectedOptionHandler}
                options={options}
                controlShouldRenderValue={true}
            />
        </>
    )
}

export default ExpirationNoteLkp;

