import React, { Component } from 'react'
import isEmail from 'validator/lib/isEmail'
import axios from 'axios'
import {Link, Redirect } from 'react-router-dom'
import Config from '../Constants/index'
import t from '../Lang/Index'
import RegisterForm from '../Pages/Register/RegisterForm'


class Register extends Component {
    constructor() {
        super();
        document.title = t.create_an_account + " - " + Config.SITE_TITLE;
        this.state = {};
    }


    render() {
        return (

            <div className="container">
                {/* Outer Row */}
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                {/* Nested Row within Card Body */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">{Config.SITE_TITLE}</h1>
                                                <h1 className="h4 text-gray-900 mb-4">{t.create_an_account}</h1>
                                            </div>
                                            <RegisterForm />
                                            <hr />
                                            <div className="text-center">
                                            <Link className="small" to="/ForgotPassword">{t.forgot_password}</Link>
                                            </div>
                                            <div className="text-center">
                                                
                                            <Link className="small" to="/Login">{t.already_have_an_account}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            // <div className="container">
            //     <div className="row">
            //         <div className="col-md-4 col-md-offset-4">
            //             <h3 className="form-signin-heading">{Config.SITE_TITLE}</h3>
            //             <h4 className="form-signin-heading">{Config.APP_TITLE}</h4>
            //             <div className="login-panel panel panel-default">
            //                 <div className="panel-heading">
            //                     <h3 className="panel-title">Welcome Back!</h3>
            //                 </div>
            //                 <div className="panel-body">
            //                     <LoginForm></LoginForm>                            
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>



        )
    }
}

export default Register