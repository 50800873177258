import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../Constants/index";
import { getIdToken } from "firebase/auth";
import Select from 'react-select';
import Axios from 'axios';

const EmployeeLkp = (props) => {

    const [data, setData] = React.useState([]);
    const [inputValue, setInputValue] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState(props.defaultValue);

    React.useEffect(() => {
         setSelectedOption(props.defaultValue);
    }, [props.defaultValue]);

    React.useEffect(() => {  
        if (inputValue != "")
            fetchData();
    }, [inputValue]);

    const fetchData = () => {

        getIdToken(props.user).then((idToken) => {

            Axios.get(Config.API_URL + "Employee/GetEmployeeListAdmin/?Skip=" + 0
                + "&Take=" + 5
                + "&SortField=" + ""
                + "&SortDir=" + ""
                + "&F_LastName=" + inputValue
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {

                var list = response.data.rowModels.map(item => {
                    return {
                        value: item.id,
                        label: item.vFullname
                    }
                });

                setData(list)
                debugger;


            }).catch(error => {
                console.log(error);
            })
        });

    }

    const handleInputChange = (newValue) => {
        setInputValue(newValue)
    };

    const selectedOptionHandler = (selectedValue) => {
        setSelectedOption(selectedValue)
        props.onChange(selectedValue);
    }

    return (
            <Select className="form-control"
                value={selectedOption}    
                onChange={selectedOptionHandler}
                onInputChange={handleInputChange}
                options={data}
                controlShouldRenderValue={true}
                isClearable={true}
            />
    )
}

export default EmployeeLkp;

