
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function CellButtonResult(select) {
    return class extends GridCell {
        render() {
            return (
                <td>
                    <button class="k-button k-flat k-button-icontext" onClick={() => { select(this.props.dataItem); }}>
                        <i class="fas fa-table" style={{"margin-right": "5px"}}></i>Items</button>                        
                </td>
            );
        }
    };
}