import React from 'react';
import { Link } from 'react-router-dom';

function BreadcrumbsNav({ lbl1, lbl1path, lbl2, lbl2path, lbl3, lbl3path }) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
        {/* Hardcoded "Home" */}
        <li className="breadcrumb-item text-sm">
          <Link className="opacity-5 text-dark" to="/">
            Home
          </Link>
        </li>

        {/* Label 1 */}
        {lbl1 && (
          <li className="breadcrumb-item text-sm">
            {lbl1path ? (
              <Link className="opacity-5 text-dark" to={lbl1path}>
                {lbl1}
              </Link>
            ) : (
              <span className="text-dark">{lbl1}</span>
            )}
          </li>
        )}

        {/* Label 2 */}
        {lbl2 && (
          <li className="breadcrumb-item text-sm">
            {lbl2path ? (
              <Link className="opacity-5 text-dark" to={lbl2path}>
                {lbl2}
              </Link>
            ) : (
              <span className="text-dark">{lbl2}</span>
            )}
          </li>
        )}

        {/* Label 3 */}
        {lbl3 && (
          <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
            {lbl3path ? (
              <Link className="text-dark" to={lbl3path}>
                {lbl3}
              </Link>
            ) : (
              <span className="text-dark">{lbl3}</span>
            )}
          </li>
        )}
      </ol>
      {/* Display the last label (lbl3) as the page title */}
      {/* {lbl3 && <h6 className="font-weight-bolder mb-0">{lbl3}</h6>} */}
    </nav>
  );
}

export default BreadcrumbsNav;
