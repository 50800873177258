import * as React from "react";
import * as ReactDOM from "react-dom";
import Config from '../../../Constants/index';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Link, Redirect } from 'react-router-dom'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { MyCommandCell } from "./myCommandCell";
import Axios from 'axios';
import react from "react";
import Alert from "../../../Component/Alert";
import { getIdToken } from "firebase/auth";


const editField = "inEdit";

const TimesheetMonthlyItemTable = (props) => {
    const title = props.title;

    const timesheetMonthlyRef = props.timesheetMonthlyId;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [calTbl, setCalTbl] = React.useState();
    const [messages, setMessages] = React.useState([]);
    const [alertDialog, setAlertDialog] = React.useState(false);

    const [temp, setTemp] = React.useState("");
    const [changed, setChanged] = React.useState(false);
    const [deletedIds, setDeletedIds] = react.useState([]);
    const [passiveMasterEquipmentRef, setPassiveMasterEquipmentRef] = React.useState(-1);
    const [passiveMasterEquipmentTitle, setPassiveMasterEquipmentTitle] = React.useState("-");
    const [masterEquipmentRef, setMasterEquipmentRef] = React.useState(-1);
    const [masterEquipmentTitle, setMasterEquipmentTitle] = React.useState("-");
    const [masterEquipmentSpecRef, setMasterEquipmentSpecRef] = React.useState(-1);
    const [masterEquipmentSpecTitle, setMasterEquipmentSpecTitle] = React.useState("-");
    const [isRedirectToReport, setIsRedirectToReport] = React.useState(false);
    const [hasAnyRow, setHasAnyRow] = React.useState(false);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        setHasAnyRow(false);

        getIdToken(props.user).then((idToken) => {
            fetch(Config.API_URL + "TimesheetMonthlyItem/GetTimesheetMonthlyItemListAdmin/?"
                + "f_TimesheetMonthlyRef=" + timesheetMonthlyRef
                + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => response.json())
                .then(json => {
                    setLoading(false);

                    const dataConvertedDate = json.rowModels.map((item) => {
                        item.taskDate = new Date(item.taskDate);
                        return item;
                        //item.id === event.dataItem.id
                        //? new PressureResultCalc().GetRow(item, equipment) : item;
                    })

                    if (dataConvertedDate.length > 0)
                        setHasAnyRow(true);

                    setData(dataConvertedDate);
                    focusFirstCell();
                });
        });
    }


    const remove = (item) => {
        if (item.isNew !== true) {
            deletedIds.push(item.id);
            setDeletedIds(deletedIds)
        }
        console.log(deletedIds);

        const newList = data.filter((record) => record.id !== item.id);
        setData(newList);
        setChanged(true);
    };

    const removeTimeToString = (date) => {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return year + '/' + month + '/' + day;
    }

    const saveChanges = () => {

        const convertedData = data.map((item) => {
            //var userTimezoneOffset = item.taskDate.getTimezoneOffset() * 60000;
            //item.taskDate = new Date(item.taskDate - userTimezoneOffset);

            item.strTaskDate = removeTimeToString(item.taskDate);
            return item;
        });

        getIdToken(props.user).then((idToken) => {

            Axios.post(Config.API_URL + "TimesheetMonthlyItem/SaveChanges",
                {
                    calResultListStr: JSON.stringify(convertedData),
                    deletedIds: deletedIds,
                    timesheetMonthlyId: parseInt(timesheetMonthlyRef)
                },
                { headers: { 'Authorization': 'Bearer ' + idToken } })
                .then(response => {
                    fetchData(timesheetMonthlyRef);
                    setChanged(false);
                    setAlertDialog(false);
                    setMessages([]);
                })
                .catch(error => {

                    let list = [];
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            list.push(p);
                        })
                    }
                    else {
                        list.push("Failed to connect to server")
                    }

                    setMessages(list);
                    setAlertDialog(true);

                    setTemp("test");
                });
        });


    };

    const cancelChanges = () => {
        fetchData(timesheetMonthlyRef);
        setDeletedIds([]);
        setChanged(false)
    };

    const itemChange = (event) => {
        const newData = data.map((item) => {
            if (item.id === event.dataItem.id)
                item.isChanged = true;

            return item.id === event.dataItem.id
                ? { ...item, [event.field || ""]: event.value }
                : item;
        });

        const calcData = newData.map((item) => {
            //item.taskDate = new Date(item.taskDate)
            return item;
            //item.id === event.dataItem.id
            //? new PressureResultCalc().GetRow(item, equipment) : item;
        })

        setChanged(true);

        setData(calcData);
    };

    const genRandomId = () => {
        //TODO not in list
        return Math.random().toString().slice(-8)
    }

    const focusFirstCell = () => {
        let firstInput = window.$(".Table" + 1 + " .k-input")[0];
        if (firstInput)
            firstInput.focus();
    }


    const addNew = () => {
        setData([getNewDataItem(), ...data]);
        setChanged(true);
        focusFirstCell();
    };

    const hideDialogs = () => {
        setAlertDialog(false);
    }   

    const getNewDataItem = () => {
        const newDataItem = {
            inEdit: true,
            Discontinued: false,
            isNew: true,
            taskDate: new Date(),
            taskTimeFrom: "8:00",
            taskTimeTo: "17:00",
            //passiveMasterEquipmentref: passiveMasterEquipmentRef,
            //masterEquipmentSpecRef: masterEquipmentSpecRef,
            //vPassiveMasterEquipmentTitle: passiveMasterEquipmentTitle,
            //vMasterEquipmentTitle: masterEquipmentTitle,
            //vMasterEquipmentSpecTitle: masterEquipmentSpecTitle,
            id: genRandomId()
        };

        debugger;
        return newDataItem;
    }

    const addNew5 = () => {
        setData([getNewDataItem(),
        getNewDataItem(),

        getNewDataItem(),
        getNewDataItem(),
        getNewDataItem(), ...data]);
        setChanged(true);
        focusFirstCell();
    };


    const CommandCell = (props) => (
        <MyCommandCell
            {...props}
            remove={remove}
            editField={editField}
        />
    );

    const passiveMasterEquipmentChanges = (id, title) => {
        setPassiveMasterEquipmentRef(id);
        setPassiveMasterEquipmentTitle(title);
    }

    const masterEquipmentSaveChanges = (id, title) => {
        setMasterEquipmentRef(id);
        setMasterEquipmentTitle(title);
        setMasterEquipmentSpecRef(0);
        setMasterEquipmentSpecTitle("-");

    }

    const masterEquipmentSpecChanges = (id, title) => {
        setMasterEquipmentSpecRef(id);
        setMasterEquipmentSpecTitle(title);
    }

    return (
        <div className={"Table" + 1}>
            <Grid
                // style={{
                //     height: "420px",
                // }}
                data={data}
                onItemChange={itemChange}
                editField={editField}
            >
                <GridToolbar>
                    <h2>{title}</h2>
                    {timesheetMonthlyRef}
                </GridToolbar>
                <GridToolbar>
                    <div class="row">
                        <div class="col-md-6">
                            <button title="Add new" className="k-button k-primary" onClick={addNew} >
                                Add new
                            </button>

                            {/* <button title="Add new 5" className="k-button k-primary" onClick={addNew5} >
                                Add new (5n)
                            </button>  */}
                        </div>
                    </div>
                </GridToolbar>
                <GridToolbar>
                    <button title="Add new 5" className="k-button k-primary" onClick={saveChanges} hidden={!changed}>
                        Save Changes
                    </button>
                    <button title="Add new 5" className="k-button k-primary" onClick={cancelChanges} hidden={!changed}>
                        Cancel Changes
                    </button>
                </GridToolbar>
                <Column cell={CommandCell} width="100px" />
                <Column field="id" title="Id" width="100px" editable={false} />
                <Column field="taskDate" title="Date" width="150px" editable={true} editor="date" />
                <Column field="taskTimeFrom" title="From" width="100px" editable={true} />
                <Column field="taskTimeTo" title="To" width="100px" editable={true} />
                <Column field="taskTitle" title="Task" width="500px" editable={true} />
                {/* <Column field="taskRemark" title="Remark" width="350px" editable={true} /> */}


            </Grid>
            {
                messages.length > 0 &&

                <Alert messages={messages}></Alert>}

{
                                    //Make Delete As Component
                                    alertDialog > 0 &&
                                    <Dialog
                                        title="Alert"
                                        onClose={hideDialogs} >

                                        {messages.length > 0 &&
                                            <ul>
                                                {messages.map((message) => {
                                                    return (
                                                        <li>{message}</li>
                                                    )
                                                })}
                                            </ul>
                                        }

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={hideDialogs}
                                            >
                                                Close
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }


        </div>

    );
};

export default TimesheetMonthlyItemTable;





