import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import moment from 'moment';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import CurrentDefaultUser from '../../Component/User/CurrentDefaultUser'
import { getIdToken } from "firebase/auth";

export default class TimesheetDaily_Add extends Component {

    constructor(props) {
        super(props);

        let id = 0;
        let isEdit = false;

        // if (this.props.match.params.Id) {
        //     id = this.props.match.params.Id;
        //     isEdit = true;
        // };

        this.state = {
            Id: id, isEdit, Title: '', Short: '', 
            isRedirectTimesheetDailyItems: false,
            messages : [],
            userOrTeamTitle : '',
            projectTitle : '',
            clientTitle : '',
            siteLocationTitle : '',
            note : '',
            pName : '',
            pPosition : '',
            pSignature : '',
            pDate : '',
            aName : '',
            aPosition : '',
            aSignature : '',
            aDate : '',
            saveEnabled: true,
        };

        this.PAGE_TITLE = "New TimesheetDaily Code";

        this.onChange = this.onChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.addEdit = this.addEdit.bind(this);
        this.edit = this.edit.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onParentChange = this.onParentChange.bind(this);
        this.onDivisionChange = this.onDivisionChange.bind(this);
        this.onTimesheetDailyTypeChange = this.onTimesheetDailyTypeChange.bind(this);
        this.onLangChange = this.onLangChange.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onCompanyChange(event, preFix) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ CompanyPreFix: preFix });
    }

    onParentChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
    }

    onDivisionChange(event, preFix) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ DivisionPreFix: preFix });
    }

    onTimesheetDailyTypeChange(event, preFix) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ TimesheetDailyTypePreFix: preFix });
    }

    onLangChange(event, preFix) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({ [name]: value });
        this.setState({ LangPreFix: preFix });
    }

    cancel = () => {
        this.setState({ isRedirectTimesheetDaily: true })
    }

    fetchData() {


        Axios.get(Config.API_URL + "CertificateOfCalibration/GetCertificateOfCalibration/?id=" + this.state.Id + "&timestamp=" + new Date().getTime(),
            {
                headers: {
                    'Authorization': 'Bearer ' + this.props.user.accessToken
                }
            })
            .then(response => {
                //TODO
                //this.setState({ Title: response.data.title });              
            });
    }

    componentDidMount() {
        if (this.state.isEdit)
            this.fetchData();
    }

    addEdit() {
        
        this.setState({ saveEnabled: false });

        if (this.state.isEdit)
            this.edit();
        else
            this.add();
    }

    

    add() {
        let messages = [];
        let self = this;

        getIdToken(this.props.user).then((idToken) => {
            Axios.post(Config.API_URL + "TimesheetDaily/Add",
                {
                    projectTitle  : this.state.projectTitle,
                    clientTitle  : this.state.clientTitle,
                    siteLocationTitle  : this.state.siteLocationTitle,
                    note : this.state.note,
                    pName : this.state.pName,
                    pPosition : this.state.pPosition,
                    pDateStr : this.state.pDate,
                    aName : this.state.aName,
                    aPosition : this.state.aPosition,
                    aDateStr  : this.state.aDate
                },
                { headers: { 'Authorization': 'Bearer ' + idToken } })
                .then(response => {
                    let id = response.data;
                    this.setState({ Id: id, isRedirectTimesheetDailyItems: true });
                })
                .catch(error => {
                    
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messages.push(p);
                        })
                    }
                    else {
                        //messages.push("خطا در برقراری ارتباط با سرور - در صورت استفاده از پروکسی آنرا غیر فعال نمایید")
                        messages.push("Failed to connect to server")
                    }
                    self.setState({ messages, isInsert: true,saveEnabled:true });
                });
        });
    }

    edit() {

        let messages = [];
        let self = this;
        debugger;

        Axios.post(Config.API_URL + "Town_Unit/edit",
            {
                Id: this.state.Id,
                Title: this.state.Title
            },
            { headers: { token: window.localStorage.getItem("apiToken") } })
            .then(response => {
                let id = response.data;
                this.setState({ Id: id, isRedirectUnit: true });
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {                    
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, isInsert: true });
            });
    }

    onUploadSuccess = (fileTblId) => {
        this.setState({ FileTblId: fileTblId });
    };

    onUploadError = () => {
        this.setState({ FileTblId: 0 });
    };

    onCurrentDefualtUserBind = (id) => {
        this.setState({ CustomerRef : id });
        this.fetchData();
    }

    render() {

        if (this.state.isRedirectTimesheetDaily) {
            return <Redirect to={"/TimesheetDaily/"}></Redirect>
        }

        if (this.state.isRedirectTimesheetDailyItems) {
            return <Redirect to={"/TimesheetDailyItems/" + this.state.Id}></Redirect>
        }

        return (
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">                
                <NavBar />
                <div className="container-fluid py-4">
                    <div className="col-lg-9 col-12 mx-auto position-relative">
                        <div className="card">
                            
                            <div className="card-header p-3 pt-2">
                                <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                    <i class="fa fas fa-calendar-check fa-fw" aria-hidden="true"></i>
                                </div>
                                <h6 className="mb-0">New Daily Timesheet</h6>
                            </div>

                            <div className="card-body pt-2">
                                <div class="row">
                                    <div className="col-12">
                                        <div className="input-group input-group-static  my-3">
                                            <label>Inspector</label>
                                             <CurrentDefaultUser onBind={this.onCurrentDefualtUserBind} user={this.props.user} ></CurrentDefaultUser>                                       
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12">
                                        <div className="input-group input-group-static  my-3">
                                            <label>Project Name</label>
                                            <input type="text" class="form-control" name="projectTitle" onChange={this.onChange} value={this.state.projectTitle} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12">
                                        <div className="input-group input-group-static  my-3">
                                            <label>Client Name</label>
                                            <input type="text" class="form-control" name="clientTitle" onChange={this.onChange} value={this.state.clientTitle} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12">
                                        <div className="input-group input-group-static  my-3">
                                            <label>Site & Location</label>
                                            <input type="text" class="form-control" name="siteLocationTitle" onChange={this.onChange} value={this.state.siteLocationTitle} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12">
                                        <div className="input-group input-group-static  my-3">
                                            <label>Note</label>
                                            <input type="text" class="form-control" name="note" onChange={this.onChange} value={this.state.note} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <h6 className="mb-0">Performed By</h6>
                                        <div className="input-group input-group-static">
                                            <label>Name</label>
                                            <input type="text" class="form-control" name="pName" onChange={this.onChange} value={this.state.pName} />
                                        </div>
                                        <div className="input-group input-group-static">
                                            <label>Position</label>
                                            <input type="text" class="form-control" name="pPosition" onChange={this.onChange} value={this.state.pPosition} />
                                        </div>                                        
                                        <div className="input-group input-group-static">
                                            <label>Date</label>
                                            <input type="date" class="form-control" name="pDate" onChange={this.onChange} value={this.state.pDate} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <h6 className="mb-0">Aproved By Client</h6>
                                        <div className="input-group input-group-static">
                                            <label>Name</label>
                                            <input type="text" class="form-control" name="aName" onChange={this.onChange} value={this.state.aName} />
                                        </div>
                                        <div className="input-group input-group-static">
                                            <label>Position</label>
                                            <input type="text" class="form-control" name="aPosition" onChange={this.onChange} value={this.state.aPosition} />
                                        </div>                                        
                                        <div className="input-group input-group-static">
                                            <label>Date</label>
                                            <input type="date" class="form-control" name="aDate" onChange={this.onChange} value={this.state.aDate} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.messages.length > 0 &&
                                    <Alert messages={this.state.messages}></Alert>}
                                <div className="d-flex justify-content-end mt-4">
                                    <button type="button" class="btn btn-light m-0" onClick={this.cancel}>
                                        Cancel
                                    </button>
                                    <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={this.addEdit} disabled={!this.state.saveEnabled} >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main >
        )
    }
}
