import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import cellButton from '../../Component/cellButton';
import cellButtonReport from '../../Component/CellButtonReport';
import CellBtnIcon from '../CellBtnIcon';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellButtonResult from '../../Component/CellButtonResults';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import cellBtnUpload from '../Doc/Component/CellBtnUpload'
import cellBtnDownload from '../Doc/Component/CellBtnDownload'
import FileUploaderGraph from "../../Component/DocumentControl/FileUploaderGraph";
import fileDownload from 'js-file-download'
import axios from 'axios';

const PoDocRevision = (props) => {

    const PAGE_TITLE = "Doc Revision Control";

    const [entityRef, setEntityRef] = React.useState(props.match.params.entityRef);
    const [data, setData] = React.useState([]);
    const [messages, setMessages] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(10);
    const [total, setTotal] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [uploadDialog, setUploadDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(false);
    const [uploadDialogCloseButtonVisible, setUploadDialogCloseButtonVisible] = React.useState(true);    

    const [sort, setSort] = React.useState([
        { field: 'id', dir: 'desc' }
    ]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const [isRedirecToInsert, setisRedirecToInsert] = React.useState(false);

    const onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    const pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        }, () => {
            this.fetchData();
        });

    }

    const onSortChange = (e) => {
        this.setState({
            sort: e.sort,
        }, () => {
            this.fetchData();
        });
    }


    const fetchData = () => {
        debugger;
        var self = this;

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "PoDocRevision/GetPoDocRevisionAdmin/?entityRef=" + entityRef
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {

                setData(response.data.rowModels);
                setTotal(response.data.total);
            }).catch(error => {
                console.log(error);
            })
        });
    }

    React.useEffect(() => {
        //TODO FetchDailyTimeSheet
        fetchData();
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);


    const insert = () => {
        setisRedirecToInsert(true);
    }

    const edit = (dataItem) => {
        /*
        this.setState({ buildingRef: dataItem.id })
        this.setState({ isRedirectToEdit: true })*/
    }

    const deleteConfirm = (dataItem) => {
        /*this.setState({ buildingRef: dataItem.id })
        this.setState({ deleteDialog: true })*/
    }

    const hideDialogs = () => {
        setDeleteDialog(false);
        setAlertDialog(false);
        setUploadDialog(false);
    }

    const showUploadDialog = (dataItem) => {
        setSelectedId(dataItem.id);
        setUploadDialog(true);
    }

    const onUploadInit = () => {
        setUploadDialogCloseButtonVisible(false);
    };

    const onUploadSuccess = () => {        
        setUploadDialogCloseButtonVisible(true);
        fetchData();
    };

    const onUploadError = () => {        
        setUploadDialogCloseButtonVisible(true);
    };

    const download = (dataItem) =>{
        const selectedId = dataItem.id;        

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "PoDocRevision/download/?docRevisionId=" + selectedId
                + "&time=" + new Date().getTime(),
                {
                    responseType: 'blob',
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {                
                fileDownload(response.data, response.headers["x-filename"]);
            }).catch(error => {                
                console.log(error);
            })
        });        
    }

    /*const delete = () => {

        let messages = [];
        let self = this;
        debugger;
        Axios.post(Config.API_URL + "CertificateOfCalibration/delete",
            {
                Id: self.state.buildingRef,
            },
            { headers: { 'Authorization': 'Bearer ' + this.props.user.accessToken } })
            .then(response => {
                //let id = response.data;
                this.setState({ deleteDialog: false });
                this.fetchData();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }
                self.setState({ messages, alertDialog: true });
            });
    }*/

    if (isRedirecToInsert) {
        return <Redirect to={'/PoDocRevision/Add/' + entityRef} />
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">



                <div className="col-12">
                    <div className="card mb-4">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                <i class="fa fa fa-file-alt fa-fw" aria-hidden="true"></i>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="mb-0">{PAGE_TITLE}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <Grid data={data} skip={skip} take={take}
                                total={total} sort={sort} pageSize={pageSize}
                                onPageChange={pageChange}
                                onSortChange={onSortChange}
                                sortable pageable resizable
                            >
                                {/* <GridToolbar>
                                    <Button onClick={insert} >New Doc Revision</Button>
                                </GridToolbar>                                 */}
                                <Column field="id" title="Id" width="60px" className="NoWrap" />
                                <Column field="revisionNumber" title="Number" width="200px" className="NoWrap" />
                                <Column field="vRevisionDate" title="Date" width="200px" className="NoWrap" />
                                <Column field="vStatusTitle" title="Status" width="200px" className="NoWrap" />                                
                                <Column cell={cellBtnUpload(showUploadDialog)} width="110px"  />
                                <Column cell={cellBtnDownload(download)} width="110px" />
                            </Grid>
                            <div>
                                {
                                    //Make Delete As Component
                                    deleteDialog &&
                                    <Dialog
                                        title="Delete"
                                        onClose={hideDialogs}
                                    >
                                        <div>
                                            Are you sure you want to delete this item?
                                            {/* آیا برای حذف {deleteTitle} مطمئن هستید؟ */}
                                        </div>

                                        <DialogActionsBar>
                                            {/* <button
                                                    className="k-button k-primary"
                                                    onClick={delete}
                                                >
                                                    Yes
                                                </button> */}
                                            <button
                                                className="k-button"
                                                onClick={hideDialogs}

                                            >
                                                No
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }


                                {                                    
                                    uploadDialog &&
                                    <Dialog
                                        title="Upload"
                                        onClose={hideDialogs}
                                        closeIcon={uploadDialogCloseButtonVisible}
                                    >
                                        <div>
                                        <FileUploaderGraph
                                            onUploadSuccess={onUploadSuccess}
                                            onUploadError={onUploadError}
                                            onUploadInit={onUploadInit}
                                            docRevisionRef={selectedId}
                                            entityController="PoDocRevision"
                                            user={user} />                                          
                                        </div>

                                        <DialogActionsBar>
                                            {/* <button
                                                    className="k-button k-primary"
                                                    onClick={delete}
                                                >
                                                    Yes
                                                </button> */}
                                            {/* <button
                                                className="k-button"
                                                onClick={hideDialogs}

                                            >
                                                No
                                            </button> */}
                                        </DialogActionsBar>
                                    </Dialog>
                                }


                                {
                                    //Make Delete As Component
                                    alertDialog > 0 &&
                                    <Dialog
                                        title="Alert"
                                        onClose={this.hideDialogs} >

                                        {messages.lPoth > 0 &&
                                            <ul>
                                                {messages.map((message) => {
                                                    return (
                                                        <li>{message}</li>
                                                    )
                                                })}
                                            </ul>
                                        }

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.hideDialogs}
                                            >
                                                Close
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main>

    )
}

export default PoDocRevision