import React, { Component } from 'react'
import Config from '../../Constants/index'
import { getIdToken } from "firebase/auth";

export default class CurrentDefaultUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            displayTitle: "-",
            userId: -1
        };

        this.onBind = this.onBind.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    onBind = (id) => {
        this.props.onBind(id);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        getIdToken(this.props.user).then((idToken) => {

            fetch(Config.API_URL + "User/GetCurrentUser?timestamp=" + new Date().getTime(),
                { headers: { 'Authorization': 'Bearer ' + idToken } })
                .then(response => response.json())
                .then(userDto => {
                    this.setState({ userId: userDto.id, displayTitle: userDto.displayTitle });
                    this.onBind(userDto.id);
                });

        });
    }


    render() {
        return (
            <input type="text" class="form-control" readOnly name={this.props.name} value={this.state.displayTitle} />
        )
    }
}
