import React, { Component } from 'react'
import Alert from '../Pages/Login/LoginForm'
import LoginForm from '../Pages/Login/LoginForm';
import LoginByGoogle from '../Pages/Login/LoginByGoogle';
import LoginByMicrosoft365Btn from '../Pages/Login/LoginByMicrosoft365Btn';
import Config from '../Constants/index'
import t from '../Lang/Index'
import { Link } from 'react-router-dom';

class Login extends Component {
    constructor() {
        super();
        document.title = Config.SITE_TITLE + "-" + Config.APP_TITLE + " - " + t.signIn ;
        this.state = {};       
    }  

    componentDidMount (){
        window.initializeDashboard();
        document.body.classList.remove('bg-gray-200');
    }

    render() {
        return (
            <main className="main-content  mt-0">
            <section>
                <div className="page-header min-vh-100">
                <div className="container">
                    <div className="row">
                    <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                        <div className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                            style={{backgroundImage: 'url("../assets/img/illustrations/bg1.jpg")', backgroundSize: 'cover'}}>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                        <div className="card card-plain">
                            <div className="card-header">
                                <h4 className="font-weight-bolder">{Config.SITE_TITLE} - Sign In</h4>                            
                            </div>
                            <div className="card-body">
                                <LoginForm />
                            </div>
                            <div className="card-footer text-center pt-0 px-lg-2 px-1">
                                <p className="mb-2 text-sm mx-auto">
                                    Don't have an account?
                                    <Link className="text-primary text-gradient font-weight-bold" to="/SignUp"> {t.signUp}</Link>
                                </p>
                                {/* <p className="mb-2 text-sm mx-auto">                                
                                    <Link className="text-primary text-gradient font-weight-bold" to="/ForgotPassword">{t.forgot_password}</Link>
                                </p>                                 */}
                                <hr/>                                
                                <div className="card-body">
                                    <LoginByGoogle/>
                                    <LoginByMicrosoft365Btn/>
                                </div>
                            </div>                                                                      
                            
                        </div>
                        
                    </div>
                    </div>
                </div>
                </div>
            </section>
            </main>






            // <div className="container">
            //     {/* Outer Row */}
            //     <div className="row justify-content-center">
            //         <div className="col-xl-6 col-lg-12 col-md-9">
            //             <div className="card o-hidden border-0 shadow-lg my-5">
            //                 <div className="card-body p-0">
            //                     {/* Nested Row within Card Body */}
            //                     <div className="row">
            //                         <div className="col-lg-12">
            //                             <div className="p-5">
            //                                 <div className="text-center">
            //                                     <h1 className="h4 text-gray-900 mb-4">{Config.SITE_TITLE}</h1>
            //                                     <h1 className="h4 text-gray-900 mb-4">{Config.APP_TITLE}</h1>
            //                                 </div>                                           
                                                // <LoginForm></LoginForm>                                             
            //                                 <hr />
            //                                 <div className="text-center">
            //                                     <Link className="small" to="/ForgotPassword">{t.forgot_password}</Link>
            //                                 </div>
            //                                 <div className="text-center">
            //                                     <Link className="small" to="/SignUp">{t.create_an_account}</Link>
            //                                 </div>
            //                                 {/* <div className="text-center">                                                
            //                                     <a className="small" href={Config.APP_BRAND_URL}>Powered By: {Config.APP_BRAND}</a>
            //                                 </div> */}
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>    
        )
    }
}

export default Login