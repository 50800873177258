import React, { useState, useEffect } from 'react'
import Config from '../../Constants/index'
import Axios from 'axios';
import CardType1 from './CardType1';
import { getAuth, onAuthStateChanged,getIdToken } from "firebase/auth";

const CardTotal = (props) => {

    const [total, setTotal] = useState(-1);

    const getData = () => {

        getIdToken(props.user).then((idToken)=> { 

            Axios.get(Config.API_URL + props.controllerUrl, {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            }
            ).then(response => {            
                setTotal(response.data);
            }).catch(error => {
                console.log(error);
            })

        });
        
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <CardType1 title={props.title} total={total} iconClass={props.iconClass} colorClass={props.colorClass} />
    )
}

export default CardTotal



