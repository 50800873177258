import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import cellButton from '../../Component/cellButton';
import cellButtonReport from '../../Component/CellButtonReport';
import CellBtnIcon from '../CellBtnIcon';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellButtonResult from '../../Component/CellButtonResults';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
//import cellBtnEmployeePrint from './Component/CellBtnEmployeePrint'
//import cellBtnEmployeeItem from './Component/CellBtnEmployeeItem'
//import cellBtnUpload from '../Timesheet/Component/CellBtnUpload'
//import cellBtnDownload from '../Timesheet/Component/CellBtnDownload'
import FileUploaderGraph from "../../Component/Timesheet/FileUploaderGraph";
import fileDownload from 'js-file-download'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'


const Employee = (props) => {

    const PAGE_TITLE = "Employees";

    const [data, setData] = React.useState([]);
    const [messages, setMessages] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(100);
    const [total, setTotal] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [alertDialog, setAlertDialog] = React.useState(false);
    const [uploadDialog, setUploadDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(false);
    const [uploadDialogCloseButtonVisible, setUploadDialogCloseButtonVisible] = React.useState(true);
    const [isRedirecToInsert, setIsRedirecToInsert] = React.useState(false);
    const [isRedirecToEdit, setIsRedirecToEdit] = React.useState(false);
    const [filterDialog,setFilterDialog] = React.useState(false);
    const [F_title,setF_title] = React.useState('');
    const [F_titleOrEmployeeCode,setF_titleOrEmployeeCode] = React.useState('');

    const [sort, setSort] = React.useState([
        { field: 'id', dir: 'desc' }
    ]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const [isRedirectToEmployeeItem, setIsRedirectToEmployeeItem] = React.useState(false);



    React.useEffect(() => {        
        fetchData();
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);

    React.useEffect(() => {
        fetchData();        
    }, sort,take,skip);


    const getFilterStr = () => {

        if (!F_titleOrEmployeeCode)
            return "";

        var result = " Search: ";

        if (F_titleOrEmployeeCode)
            result = result + "Employee title Or Code contains:" + F_titleOrEmployeeCode;

        return result;
    }

    const showfilterDialog = () => {
        this.setState({ filterDialog: true });
    }

    const onFilter = () => {
        this.fetchData();
        this.setState({ filterDialog: false });
    }

    const removeFilter = () => {
        this.setState({
            F_titleOrEmployeeCode: '',
        }, () => { this.fetchData() });
    }

    const pageChange = (event) => {
        setSkip(event.page.skip);
        setTake(event.page.take);
    }

    
    const onSortChange = (e) => {
        setSort(e.sort);        
    }

    const fetchData = () => {

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "Employee/GetEmployeeListAdmin/?Skip=" + skip
                + "&Take=" + take
                + "&SortField=" + (sort[0] ? sort[0].field : "")
                + "&SortDir=" + (sort[0] ? sort[0].dir : "")
                + "&F_title=" + F_title
                + "&F_titleOrEmployeeCode=" + F_titleOrEmployeeCode
                + "&time=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            ).then(response => {
                setData(response.data.rowModels);
                setTotal(response.data.total);                
            }).catch(error => {
                console.log(error);
            })
        });
    }

    const deleteEntity = () => {

        let messages = [];

        getIdToken(user).then((idToken) => {

        Axios.post(Config.API_URL + "Employee/delete",
            {
                Id: selectedId,
            },
            { headers: {
                'Authorization': 'Bearer ' + idToken
            }}
            )
            .then(response => {
                //let id = response.data;
                deleteDialog(false)                
                fetchData();
            })
            .catch(error => {
                if (error.response && error.response.status == 400) {
                    error.response.data.message.map(p => {
                        messages.push(p);
                    })
                }
                else {
                    messages.push("Failed to connect to server")
                }

                setAlertDialog(true)
                setMessages(messages);
            });
        });
    }


    const insert = () => {
        setIsRedirecToInsert(true);        
    }

    const edit = (dataItem) => {
        setSelectedId(dataItem.id)
        setIsRedirecToEdit(true);        
    }

    const selectTimeSheetItem = (dataItem) => {
        setSelectedId(dataItem.id);
        setIsRedirectToEmployeeItem(true);
    }

    const deleteConfirm = (dataItem) => {
        debugger;
        setSelectedId(dataItem.id);
        setDeleteDialog(true);
    }


    const hideDialogs = () => {
        setDeleteDialog(false);
        setAlertDialog(false);
        setUploadDialog(false);
    }   

  


    if (isRedirecToInsert) {
        return <Redirect to={'/Employee/Add'} />
    }

    if (isRedirecToEdit) {
        return <Redirect to={'/Employee/Edit/' + selectedId} />
    }

    return (


        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            {/* Navbar */}
            <NavBar />
            <div className="container-fluid py-4">


                <div className="col-12">
                    <div className="card mb-4">
                        <div class="card-header p-3 pt-2">
                            <div class="icon icon-lg icon-shape bg-gradient-info shadow text-center border-radius-xl mt-n4 float-start">
                                <i class="fa fas fa-user fa-fw" aria-hidden="true"></i>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="mb-0">{PAGE_TITLE}</h6>
                                </div>

                            </div>
                        </div>


                        <div className="card-body px-0 pt-0 pb-2">

                            <Grid data={data} skip={skip} take={take}
                                total={total} sort={sort} pageSize={pageSize}
                                onPageChange={pageChange}
                                onSortChange={onSortChange}
                                sortable pageable resizable
                            >

                                {/* <GridToolbar>
                                        <Button iconClass="fa fa-filter"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={this.showfilterDialog}>Filter</Button>
                                        <Button iconClass="fa fa-times"
                                            style={{ 'margin-left': '5px' }}
                                            onClick={this.removeFilter}>Remove</Button>
                                        {this.getFilterStr()}
                                    </GridToolbar> */}

                                {/* <GridToolbar>
                                    <Button onClick={insert} >New Course</Button>
                                </GridToolbar>       */}
                                <Column field="id" title="Id" width="60px" className="NoWrap" />                                
                                {/* <Column cell={cellEditDeleteButton(edit, deleteConfirm)}  width="100px" />                                  */}
                                <Column field="firstName" title="Firstname" width="250px" className="NoWrap" />
                                <Column field="lastName" title="Lastname" width="300px" className="NoWrap" />
                                <Column field="taxesDeductions" title="Taxes Deductions" width="250px" className="NoWrap" />
                                <Column field="createBy" title="Create By" width="200px" className="NoWrap" />
                                <Column field="vCreateDate" title="Create Date" width="200px" className="NoWrap" />
                            </Grid>
                            <div>



                                {
                                    //Make Delete As Component
                                    deleteDialog &&
                                    <Dialog
                                        title="Delete"
                                        onClose={()=>{setDeleteDialog(false)}}
                                    >
                                        <div>
                                            Are you sure you want to delete this item?
                                            {/* آیا برای حذف {deleteTitle} مطمئن هستید؟ */}
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={deleteEntity}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className="k-button"
                                                onClick={()=>{setDeleteDialog(false)}}

                                            >
                                                No
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }

                                {
                                    //Make Delete As Component
                                    alertDialog > 0 &&
                                    <Dialog
                                        title="Alert"
                                        onClose={hideDialogs} >

                                        {messages.length > 0 &&
                                            <ul>
                                                {messages.map((message) => {
                                                    return (
                                                        <li>{message}</li>
                                                    )
                                                })}
                                            </ul>
                                        }

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={hideDialogs}>
                                                Close
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                }

                                {filterDialog &&
                                    <Dialog
                                        title="Filter"
                                        onClose={() => { this.setState({ filterDialog: false }) }}
                                        width={250}
                                    >
                                        <div>


                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="input-group input-group-static">
                                                        <label>Employee Title or Code</label>
                                                        <input type="text" class="form-control" name="F_titleOrEmployeeCode"
                                                            onChange={this.onChange} value={F_titleOrEmployeeCode} />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Status
                                                    </label>
                                                    <input type="text" class="form-control" name="F_title"
                                                        onChange={this.onChange} value={F_title} />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Created By
                                                    </label>
                                                    <input type="text" class="form-control" name="F_title"
                                                        onChange={this.onChange} value={F_title} />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">
                                                        Parent Employee
                                                    </label>
                                                    <input type="text" class="form-control" name="F_title"
                                                        onChange={this.onChange} value={F_title} />
                                                </div> */}
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button k-primary"
                                                onClick={this.onFilter}
                                            >
                                                OK
                                            </button>
                                            <button
                                                className="k-button"
                                                onClick={() => { this.setState({ filterDialog: false }) }}
                                            >
                                                Cancel
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>}

                                {/* {
    loading && <Dialog>
        <i class='fa fa-spinner fa-spin '></i> Loading...
    </Dialog>
} */}

                            </div>

                           



                        </div>
                    </div>
                </div>




            </div>

            <Footer />
        </main>


    )
}

export default Employee;
