import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../Constants/index";
import Choices from 'choices.js';
import { getIdToken } from "firebase/auth";
import Axios from 'axios';

const DocLkp = (props) => {

    React.useEffect(() => {
        initChoicer();
    }, []);

    const initChoicer = () => {
        const choicer = new Choices("#" + props.name, {
            searchEnabled: true,
            searchPlaceholderValue: 'Search Document Title or Code',            
            removeItemButton : true
        });

        choicer.passedElement.element.addEventListener(
            'search',
            function (event) {
                // do something creative here...
                //console.log(event.detail.id);
                console.log(event.detail.value);
                //console.log(event.detail.label);
                //console.log(event.detail.customProperties);
                //console.log(event.detail.groupValue);

                let searchedTxt = event.detail.value;

                getIdToken(props.user).then((idToken) => {

                    Axios.get(Config.API_URL + "AsmeDoc/GetAsmeDocListAdmin/?Skip=" + 0
                        + "&Take=" + 10
                        + "&SortField=" + ""
                        + "&SortDir=" + ""
                        + "&F_title=" + ""
                        + "&F_titleOrDocCode=" + searchedTxt
                        + "&F_parentOnly=" + props.parentOnly
                        + "&time=" + new Date().getTime(),
                        {
                            headers: {
                                'Authorization': 'Bearer ' + idToken
                            }
                        }
                    ).then(response => {
                        
                        var list = response.data.rowModels.map(item => {
                            return {
                                value: item.id,
                                id: item.id,
                                label: item.docCode + ' | ' + item.title
                            }
                        });

                        choicer.setChoices(list,
                            'value',
                            'label',
                            true,
                        );


                    }).catch(error => {
                        console.log(error);
                    })
                });

            },
            false,
        );
    }

    const onChange = (event) => {
        props.onChange(event);
    }
    
    return (
        <>
            <select className="form-control"
                id={props.name}
                name={props.name}
                onChange={onChange}
                value={props.selectedValue} >
                <option value={0} >-</option>
            </select>
        </>
    )
}

export default DocLkp;

