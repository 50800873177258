import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import moment from 'moment';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import CurrentDefaultUser from '../../Component/User/CurrentDefaultUser'
import AcdCourseLkp from '../../Component/Acd/AcdCourseLkp.js';
import ExpirationNoteLkp from '../../Component/Acd/ExpirationNoteLkp';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'

const AcdCert_Add = (props) => {

    const PAGE_TITLE = "Add/Edit Certificate";

    const [id, setId] = React.useState(props.match.params.Id);
    const isEdit = id > 0;

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const [isRedirectAcdCert, setIsRedirectAcdCert] = React.useState(false);
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [messages, setMessages] = React.useState([]);
    const [participantFullName, setParticipantFullName] = React.useState('');
    const [reCert, setReCert] = React.useState(false);
    const [dateOfIssue, setDateOfIssue] = React.useState('');
    const [courseRef, setCourseRef] = React.useState(0);
    const [vCourseTitle, setVCourseTitle] = React.useState('');
    const [expirationNote, setExpirationNote] = React.useState('');
    const [certificateNumber, setCertificateNumber] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const participantFullNameHandler = (e) => setParticipantFullName(e.target.value);
    const reCertHandler = (e) => setReCert(e.target.checked);
    const dateOfIssueHandler = (e) => setDateOfIssue(e.target.value);
    const acdCourseLkpHandler = (selectedValue) => {
        if (selectedValue) {
            setVCourseTitle(selectedValue.label);
            setCourseRef(selectedValue.value);
        }
        else {
            setVCourseTitle("");
            setCourseRef(0);
        }
    }




    React.useEffect(() => {
        if (isEdit)
            fetchData();
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);

    const fetchData = () => {

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "AcdCert/GetAcdCert/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    var item = response.data;
                    setId(item.id);
                    setCertificateNumber(item.certificateNumber);
                    setParticipantFullName(item.participantFullName)
                    setReCert(item.reCert);
                    setDateOfIssue(item.dateOfIssue.replace("T00:00:00", ''));
                    setExpirationNote(item.expirationNote);
                    setCourseRef(item.acdCourseRef);
                    fetchCourse(item.acdCourseRef)
                });
        });
    }

    const fetchCourse = (acdCourseRef) => {

        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "AcdCourse/GetAcdCourse/?id=" + acdCourseRef + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    var item = response.data;
                    setVCourseTitle(item.title)
                });
        });
    }


    const addEdit = () => {

        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "AcdCert/Add",
                {
                    ParticipantFullName: participantFullName,
                    ReCert: reCert,
                    DateOfIssue: dateOfIssue == "" ? '1900-01-01' : dateOfIssue,
                    AcdCourseRef: courseRef,
                    ExpirationNote: expirationNote
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    setIsRedirectAcdCert(true);
                    setSaveEnabled(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            debugger;
            Axios.post(Config.API_URL + "AcdCert/Edit",
                {
                    Id: id,
                    ParticipantFullName: participantFullName,
                    ReCert: reCert,
                    DateOfIssue: dateOfIssue == "" ? '1900-01-01' : dateOfIssue,
                    AcdCourseRef: courseRef,
                    ExpirationNote: expirationNote
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    setSaveEnabled(true);
                    setIsRedirectAcdCert(true);
                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }


    if (isRedirectAcdCert)
        return <Redirect to={"/AcdCert/"}></Redirect>

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">

                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fas fa-certificate fa-fw" aria-hidden="true"></i>
                            </div>
                            
                            <h6 className="mb-0">{PAGE_TITLE + " " + certificateNumber}</h6>                            
                            
                        </div>

                        <div className="card-body pt-2">
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Participant</label>
                                        <input type="text" class="form-control" name="participantFullName" onChange={participantFullNameHandler}
                                            value={participantFullName} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            <input type="checkbox" name="reCert" onChange={reCertHandler}
                                                value={reCert} checked={reCert} /> Re-Certification
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Date</label>
                                        <input type="date" class="form-control" name="dateOfIssue" onChange={dateOfIssueHandler} value={dateOfIssue} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Expiration Note</label>
                                        <ExpirationNoteLkp name="expirationNote" user={user}
                                            onChange={setExpirationNote}
                                            defaultValue={{ label: expirationNote, value: expirationNote }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Course</label>
                                        <AcdCourseLkp name="courseRef" user={user} onChange={acdCourseLkpHandler}
                                            defaultValue={{ label: vCourseTitle, value: courseRef }}

                                        //value={courseRef}
                                        />
                                    </div>
                                </div>
                            </div>

                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={() => { setIsRedirectAcdCert(true) }}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default AcdCert_Add;
