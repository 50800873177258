import React, { Component } from 'react'
import { Grid, GridColumn as Column, GridToolbar, GridCell } from '@progress/kendo-react-grid';
import cellWithEditing from '../CellWithEditing'
import { Redirect } from 'react-router-dom'
import Config from '../../Constants/index'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import cellEditDeleteButton from '../../Component/cellEditDeleteButton';
import Axios from 'axios';
import CellDeleteButton from '../../Component/CellDeleteButton';
import cellButton from '../../Component/cellButton';
import cellButtonReport from '../../Component/CellButtonReport';
import CellBtnIcon from '../CellBtnIcon';
import UserDefaultCustomerText from '../../Component/Calibration/UserDefaultCustomerText';
import CellButtonResult from '../../Component/CellButtonResults';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import cellBtnAcdCertPrint from './Component/CellBtnAcdCertPrint'
//import cellBtnAcdCertItem from './Component/CellBtnAcdCertItem'
//import cellBtnUpload from '../Timesheet/Component/CellBtnUpload'
//import cellBtnDownload from '../Timesheet/Component/CellBtnDownload'
import FileUploaderGraph from "../../Component/Timesheet/FileUploaderGraph";
import fileDownload from 'js-file-download'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
import AcdCertHistory from '../../Component/History/AcdCertHistory';
import Alert from '../../Component/Alert';

const AcdCert_Add = (props) => {

    const PAGE_TITLE = "Add/Edit Course";

    const [id, setId] = React.useState(props.match.params.Id);
    const isEdit = id > 0;

    const [isRedirectAcdCourse, setIsRedirectAcdCourse] = React.useState(false);
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [messages, setMessages] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [according, setAccording] = React.useState('');
    const [contentLine1, setContentLine1] = React.useState('');
    const [contentLine2, setContentLine2] = React.useState('');
    const [contentLine3, setContentLine3] = React.useState('');
    const [contentLine4, setContentLine4] = React.useState('');
    const [contentLine5, setContentLine5] = React.useState('');
    const [contentLine6, setContentLine6] = React.useState('');
    const [contentLine7, setContentLine7] = React.useState('');
    const [contentLine8, setContentLine8] = React.useState('');
    const [contentLine9, setContentLine9] = React.useState('');

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onTitleChange = (e) => setTitle(e.target.value);
    const onAccordingChange = (e) => setAccording(e.target.value);
    const onContentLine1Change = (e) => setContentLine1(e.target.value);
    const onContentLine2Change = (e) => setContentLine2(e.target.value);
    const onContentLine3Change = (e) => setContentLine3(e.target.value);
    const onContentLine4Change = (e) => setContentLine4(e.target.value);
    const onContentLine5Change = (e) => setContentLine5(e.target.value);
    const onContentLine6Change = (e) => setContentLine6(e.target.value);
    const onContentLine7Change = (e) => setContentLine7(e.target.value);
    const onContentLine8Change = (e) => setContentLine8(e.target.value);
    const onContentLine9Change = (e) => setContentLine9(e.target.value);

    React.useEffect(() => {
        //TODO FetchDailyTimeSheet
        fetchData();
        document.title = PAGE_TITLE + " | " + Config.APP_TITLE + ' | ' + Config.SITE_TITLE;
    }, []);

    //TODO use effect
    const fetchData = () => {

        getIdToken(user).then((idToken) => {
        Axios.get(Config.API_URL + "AcdCourse/GetAcdCourse/?id=" + id + "&timestamp=" + new Date().getTime(),
            {
                headers: {
                    'Authorization': 'Bearer ' + idToken
                }
            })
            .then(response => {
                setId(response.data.id);
                setTitle(response.data.title);
                setAccording(response.data.according);
                setContentLine1(response.data.contentLine1);
                setContentLine2(response.data.contentLine2);
                setContentLine3(response.data.contentLine3);
                setContentLine4(response.data.contentLine4);
                setContentLine5(response.data.contentLine5);
                setContentLine6(response.data.contentLine6);
                setContentLine7(response.data.contentLine7);
                setContentLine8(response.data.contentLine8);
                setContentLine9(response.data.contentLine9);                
            });
        });
    } 

    const addEdit = () => {

        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "AcdCourse/Add",
                {
                    Title: title,
                    According: according,
                    ContentLine1: contentLine1,
                    ContentLine2: contentLine2,
                    ContentLine3: contentLine3,
                    ContentLine4: contentLine4,
                    ContentLine5: contentLine5,
                    ContentLine6: contentLine6,
                    ContentLine7: contentLine7,
                    ContentLine8: contentLine8,
                    ContentLine9: contentLine9
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    setIsRedirectAcdCourse(true);
                    setSaveEnabled(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "AcdCourse/Edit",
                {
                    Id: id,
                    Title: title,
                    According: according,
                    ContentLine1: contentLine1,
                    ContentLine2: contentLine2,
                    ContentLine3: contentLine3,
                    ContentLine4: contentLine4,
                    ContentLine5: contentLine5,
                    ContentLine6: contentLine6,
                    ContentLine7: contentLine7,
                    ContentLine8: contentLine8,
                    ContentLine9: contentLine9
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {                    
                    setSaveEnabled(true);
                    setIsRedirectAcdCourse(true);                                       

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }



    if (isRedirectAcdCourse) {
        return <Redirect to={"/AcdCourse/"}></Redirect>
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">

                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fas fa-graduation-cap fa-fw" aria-hidden="true"></i>
                            </div>
                            {isEdit &&
                                <h6 className="mb-0">Edit Course ({id})</h6>
                            }
                            {!isEdit &&
                                <h6 className="mb-0">Add New Course</h6>
                            }
                        </div>

                        <div className="card-body pt-2">                      
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>Title</label>
                                        <input type="text" class="form-control" name="title" onChange={onTitleChange} value={title} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>According</label>
                                        <input type="text" class="form-control" name="according" onChange={onAccordingChange} value={according} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine1</label>
                                        <input type="text" class="form-control" name="contentLine1" onChange={onContentLine1Change} value={contentLine1} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine2</label>
                                        <input type="text" class="form-control" name="contentLine2" onChange={onContentLine2Change} value={contentLine2} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine3</label>
                                        <input type="text" class="form-control" name="contentLine3" onChange={onContentLine3Change} value={contentLine3} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine4</label>
                                        <input type="text" class="form-control" name="contentLine4" onChange={onContentLine4Change} value={contentLine4} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine5</label>
                                        <input type="text" class="form-control" name="contentLine5" onChange={onContentLine5Change} value={contentLine5} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine6</label>
                                        <input type="text" class="form-control" name="contentLine6" onChange={onContentLine6Change} value={contentLine6} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine7</label>
                                        <input type="text" class="form-control" name="contentLine7" onChange={onContentLine7Change} value={contentLine7} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine8</label>
                                        <input type="text" class="form-control" name="contentLine8" onChange={onContentLine8Change} value={contentLine8} />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static  my-3">
                                        <label>ContentLine9</label>
                                        <input type="text" class="form-control" name="contentLine9" onChange={onContentLine9Change} value={contentLine9} />
                                    </div>
                                </div>
                            </div>

                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={()=>{setIsRedirectAcdCourse(true)}}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )


}

export default AcdCert_Add;