import * as React from "react"
import * as ReactDOM from "react-dom"
import Config from "../../Constants/index";
import Choices from 'choices.js';
import { getIdToken } from "firebase/auth";

const LangLkp = (props) => {
 
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {

        getIdToken(props.user).then((idToken) => {

            fetch(Config.API_URL + "Lang/GetLangList/?timestamp=" + new Date().getTime(),
                { headers: { 'Authorization': 'Bearer ' + idToken } }
            )
                .then(response => response.json())
                .then(json => {
                    var list = json.map(item => {
                        return {
                            value: item.id,
                            id: item.id,
                            label: item.preFixChar + ' | ' + item.title,
                            preFixChar: item.preFixChar,
                        }
                    });

                    setData(json);
                    initChoicer(list);
                });
        });

    }

    const initChoicer = (data) => {
        const choicer = new Choices("#" + props.name, {
            searchEnabled: true,
            choices: data
        });
    }

    const onChange = (event) => {
        let preFixChar = '***';

        if (event.target.value != 0)
            preFixChar = data.filter(item => item.id == event.target.value)[0].preFixChar;

        props.onChange(event, preFixChar);
    }

    return (
        <>
            <select className="form-control"
                id={props.name}
                name={props.name}
                onChange={onChange}
                value={props.selectedValue} >
                <option value={0} >-</option>
            </select>
        </>
    )
}

export default LangLkp;

