import React, { Component, useEffect } from 'react'
import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Login';
import Register from './Pages/Register'
import SignUp from './Pages/SignUp'
import ForgotPassword from './Pages/ForgotPassword'
import ResetPassword from './Pages/ResetPassword'
import ChangePassword from './Pages/ChangePassword'
import { BrowserRouter, Route } from "react-router-dom";
import { Redirect } from 'react-router-dom'
import Config from './Constants';
import Doc from './Pages/Doc/Doc'
import Doc_Add from './Pages/Doc/Doc_Add';
import SideBar from './Navigation/SideBar'
import TopBar from './Navigation/TopBar'
import { matchPath } from "react-router";
import GoogleCallBack from './Pages/Login/GoogleCallBack';
import Company from './Pages/Company/Company';
import Lang from './Pages/Lang/Lang';
import DocType from './Pages/DocType/DocType';
import Division from './Pages/Division/Division';
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import TimesheetDaily from './Pages/Timesheet/TimesheetDaily'
import TimesheetDaily_Add from './Pages/Timesheet/TimesheetDaily_Add'
import TimesheetDailyItems from './Pages/Timesheet/TimesheetDailyItems';
import DocRevision_Add from './Pages/DocRevision/DocRevision_Add';
import DocRevision from './Pages/DocRevision/DocRevision';

import EngDoc from './Pages/EngDoc/EngDoc'
import EngDoc_Add from './Pages/EngDoc/EngDoc_Add';
import EngDocType from './Pages/EngDocType/EngDocType';
import EngDocRevision_Add from './Pages/EngDocRevision/EngDocRevision_Add';
import EngDocRevision from './Pages/EngDocRevision/EngDocRevision';

import PoDoc from './Pages/PoDoc/PoDoc'
import PoDoc_Add from './Pages/PoDoc/PoDoc_Add';
import PoDocType from './Pages/PoDocType/PoDocType';
import PoDocRevision_Add from './Pages/PoDocRevision/PoDocRevision_Add';
import PoDocRevision from './Pages/PoDocRevision/PoDocRevision';

import AsmeDoc from './Pages/AsmeDoc/AsmeDoc'
import AsmeDoc_Add from './Pages/AsmeDoc/AsmeDoc_Add';
import AsmeDocType from './Pages/AsmeDocType/AsmeDocType';
import AsmeDocRevision_Add from './Pages/AsmeDocRevision/AsmeDocRevision_Add';
import AsmeDocRevision from './Pages/AsmeDocRevision/AsmeDocRevision';

import AcdCert from './Pages/Acd/AcdCert'
import AcdCert_Add from './Pages/Acd/AcdCert_Add'
import AcdCourse from './Pages/Acd/AcdCourse'
import AcdCourse_Add from './Pages/Acd/AcdCourse_Add'

import TimesheetMonthly from './Pages/Timesheet/TimesheetMonthly'
import TimesheetMonthly_Add from './Pages/Timesheet/TimesheetMonthly_Add'
import TimesheetMonthlyItems from './Pages/Timesheet/TimesheetMonthlyItems';

import Employee from './Pages/HumanResource/Employee';
import Employee_Add from './Pages/HumanResource/Employee_Add';

import EmployeeContract from './Pages/HumanResource/EmployeeContract';
import EmployeePayrollPeriod from './Pages/HumanResource/EmployeePayrollPeriod';
import TimesheetMonthlyItem from './Pages/Timesheet/TimesheetMonthlyItems';

const Layout = (props) => {

  //const [user] = useAuthState(getAuth());

  document.body.classList.add('bg-gray-200');
  document.body.classList.add('g-sidenav-show');

  const auth = getAuth();

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    window.initializeDashboard();
  });

  const loggedIn = () => {
    return false;
  }

  const requireAuth = (nextState, replace) => {
    if (!this.loggedIn()) {
      replace({
        pathname: 'Login'
      })
    }
  }

  const isRegisterPath = matchPath(window.location.pathname, {
    path: "/Register",
    exact: true,
    strict: false
  });

  const isSignUpPath = matchPath(window.location.pathname, {
    path: "/SignUp",
    exact: true,
    strict: false
  });

  if (isRegisterPath)
    return <Register />;

  if (isSignUpPath)
    return <SignUp />;

  const isForgotPassword = matchPath(window.location.pathname, {
    path: "/ForgotPassword",
    exact: true,
    strict: false
  });

  if (isForgotPassword)
    return <ForgotPassword />;

  const isResetPassword = matchPath(window.location.pathname, {
    path: "/ResetPassword",
    exact: true,
    strict: false
  });

  if (isResetPassword)
    return <ResetPassword />;

  const isGoogleCallBack = matchPath(window.location.pathname, {
    path: "/GoogleCallBack",
    exact: true,
    strict: false
  });

  if (isGoogleCallBack)
    return <GoogleCallBack />;

  const isLoginPath = matchPath(window.location.pathname, {
    path: "/Login",
    exact: true,
    strict: false
  });

  if (loading) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>)
  }

  if (!user) {
    //window.localStorage.setItem('accessToken','')
    return <Login />;
  }

  if (!user && isLoginPath)
    return <Login />;

  if (isLoginPath)
    return <Login />;




  return (
    <div>
      <SideBar user={user} ></SideBar>
      <Route exact path="/" component={() => (<Dashboard user={user} />)} onEnter={requireAuth} />            
      <Route exact path="/Company" component={() => (<Company user={user} />)} onEnter={requireAuth} />
      <Route exact path="/Lang" component={() => (<Lang user={user} />)} onEnter={requireAuth} />
      <Route exact path="/Division" component={() => (<Division user={user} />)} onEnter={requireAuth} />
      <Route exact path="/DocType" component={() => (<DocType user={user} />)} onEnter={requireAuth} />
      <Route exact path="/Doc" component={() => (<Doc user={user} />)} />
      <Route exact path="/Doc/Add/" component={() => (<Doc_Add user={user} />)} />
      <Route exact path="/Doc/Edit/:Id" component={Doc_Add} OnEnter={requireAuth} />
      <Route exact path="/DocRevision/:entityRef" component={DocRevision} />
      <Route exact path="/DocRevision/Add/:entityRef" component={() => (<DocRevision_Add user={user} />)} />
      <Route exact path="/DocRevision/Edit/:Id" component={DocRevision_Add} OnEnter={requireAuth} />


      <Route exact path="/EngDocType" component={() => (<EngDocType user={user} />)} onEnter={requireAuth} />
      <Route exact path="/EngDoc" component={() => (<EngDoc user={user} />)} />
      <Route exact path="/EngDoc/Add/" component={() => (<EngDoc_Add user={user} />)} />
      <Route exact path="/EngDoc/Edit/:Id" component={EngDoc_Add} OnEnter={requireAuth} />
      <Route exact path="/EngDocRevision/:entityRef" component={EngDocRevision} />
      <Route exact path="/EngDocRevision/Add/:entityRef" component={() => (<EngDocRevision_Add user={user} />)} />
      <Route exact path="/EngDocRevision/Edit/:Id" component={EngDocRevision_Add} OnEnter={requireAuth} />

      <Route exact path="/PoDocType" component={() => (<PoDocType user={user} />)} onEnter={requireAuth} />
      <Route exact path="/PoDoc" component={() => (<PoDoc user={user} />)} />
      <Route exact path="/PoDoc/Add/" component={() => (<PoDoc_Add user={user} />)} />
      <Route exact path="/PoDoc/Edit/:Id" component={PoDoc_Add} OnEnter={requireAuth} />
      <Route exact path="/PoDocRevision/:entityRef" component={PoDocRevision} />
      <Route exact path="/PoDocRevision/Add/:entityRef" component={() => (<PoDocRevision_Add user={user} />)} />
      <Route exact path="/PoDocRevision/Edit/:Id" component={PoDocRevision_Add} OnEnter={requireAuth} />


      <Route exact path="/AsmeDocType" component={() => (<AsmeDocType user={user} />)} onEnter={requireAuth} />
      <Route exact path="/AsmeDoc" component={() => (<AsmeDoc user={user} />)} />
      <Route exact path="/AsmeDoc/Add/" component={() => (<AsmeDoc_Add user={user} />)} />
      <Route exact path="/AsmeDoc/Edit/:Id" component={AsmeDoc_Add} OnEnter={requireAuth} />
      <Route exact path="/AsmeDocRevision/:entityRef" component={AsmeDocRevision} />
      <Route exact path="/AsmeDocRevision/Add/:entityRef" component={() => (<AsmeDocRevision_Add user={user} />)} />
      <Route exact path="/AsmeDocRevision/Edit/:Id" component={AsmeDocRevision_Add} OnEnter={requireAuth} />

      <Route exact path="/TimesheetDaily" component={() => (<TimesheetDaily user={user} />)} />
      <Route exact path="/TimesheetDaily/Add/" component={() => (<TimesheetDaily_Add user={user} />)} />
      <Route exact path="/TimesheetDaily/Edit/:Id" component={() => (<TimesheetDaily_Add user={user} />)} />
      <Route exact path="/TimesheetDailyItems/:entityRef" component={TimesheetDailyItems} />

      <Route exact path="/TimesheetMonthly" component={() => (<TimesheetMonthly user={user} />)} />
      <Route exact path="/TimesheetMonthly/Add/" component={() => (<TimesheetMonthly_Add user={user} />)} />
      <Route exact path="/TimesheetMonthly/Edit/:Id" component={() => (<TimesheetMonthly_Add user={user} />)} />
      <Route exact path="/TimesheetMonthlyItem/:entityRef" component={TimesheetMonthlyItem} />

      <Route exact path="/Employee" component={() => (<Employee user={user} />)} />
      <Route exact path="/Employee/Add/" component={Employee_Add} />
      <Route exact path="/Employee/Edit/:Id" component={Employee_Add} />

      <Route exact path="/EmployeeContract" component={() => (<EmployeeContract user={user} />)} />
      {/* <Route exact path="/EmployeeContract/Add/" component={EmployeeContract_Add} />
      <Route exact path="/EmployeeContract/Edit/:Id" component={EmployeeContract_Add} /> */}

      <Route exact path="/EmployeePayrollPeriod" component={() => (<EmployeePayrollPeriod user={user} />)} />
      {/* <Route exact path="/EmployeeContract/Add/" component={EmployeeContract_Add} />
      <Route exact path="/EmployeeContract/Edit/:Id" component={EmployeeContract_Add} /> */}

      <Route exact path="/AcdCert" component={() => (<AcdCert user={user} />)} />
      <Route exact path="/AcdCert/Add/" component={AcdCert_Add} />
      <Route exact path="/AcdCert/Edit/:Id" component={AcdCert_Add} />
      <Route exact path="/AcdCourse" component={() => (<AcdCourse user={user} />)} />
      <Route exact path="/AcdCourse/Add/" component={AcdCourse_Add} />
      <Route exact path="/AcdCourse/Edit/:Id" component={AcdCourse_Add} />
      <Route exact path="/ChangePassword" component={ChangePassword} onEnter={requireAuth} />      
    </div>
  )

}

export default Layout;